import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import api from '../../Hooks/ApiHandler';
import ScribbleText from '../UI/ui_scribble_text.component';
import { motion, AnimatePresence } from 'framer-motion';
import { CreditCard, Check, CircleHelp, Gift } from 'lucide-react';
import PropTypes from 'prop-types';
import CouponModal from '../Payment/Coupon';
import CreditsExplanation from '../Payment/CreditsExplanation';
import Donation from '../Payment/Donation';

const stripePromise = loadStripe(process.env?.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const FeatureItem = ({ children, isCredit = false, bonusAmount = 0, onInfoClick }) => (
    <li className="flex items-center gap-2">
        {!isCredit && <Check className={`flex-shrink-0 w-6 h-6 text-yellow-600`} />}
        <div className={`font-children ${isCredit ? 'text-2xl font-bold text-blue-600' : 'text-lg font-semibold text-gray-700'}`}>
            {isCredit ? (
                <span className="flex items-center gap-2">
                    <span className="text-lg">for</span> {children}{" "}
                    {bonusAmount > 0 && (
                        <span className="text-yellow-600 text-xl -ml-1">+{bonusAmount} </span>
                    )}
                    Credits
                    <button
                        onClick={onInfoClick}
                        className="text-gray-400 hover:text-blue-600 transition-colors p-1 rounded-full hover:bg-blue-50"
                    >
                        <CircleHelp className="w-4 h-4" />
                    </button>
                </span>
            ) : children}
        </div>
    </li>
);

FeatureItem.propTypes = {
    children: PropTypes.node.isRequired,
    isCredit: PropTypes.bool,
    bonusAmount: PropTypes.number,
    onInfoClick: PropTypes.func
};

const BonusSticker = ({ amount }) => (
    <div className="absolute -right-10 top-2 sm:top-4 bg-yellow-400 text-blue-800 font-children font-bold py-2 px-12 shadow-lg transform rotate-12 z-10 flex items-center gap-2">
        <Gift className="w-5 h-5" />
        +{amount} Credits Gifted
    </div>
);

BonusSticker.propTypes = {
    amount: PropTypes.number.isRequired
};

const PaymentContent = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isCouponModalOpen, setIsCouponModalOpen] = useState(false);
    const [isExplanationOpen, setIsExplanationOpen] = useState(false);
    const [isDonationOpen, setIsDonationOpen] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const productIds = ['prod_RU74MK4UGqVlAK', 'prod_RU74px8GwRBzAU', 'prod_RU74d9iuZSu49E'];

    const handlePayment = async (productId) => {
        setSelectedProductId(productId);
        setIsDonationOpen(true);
    };

    const handleDonationProceed = async (donationAmount) => {
        try {
            setLoading(true);
            setError(null);
            setIsDonationOpen(false);

            const { data } = await api.post('/user/create-checkout-session', {
                productId: selectedProductId,
                donationAmount: donationAmount
            });
            
            if (data.url) {
                sessionStorage.setItem('paymentInProgress', 'true');
                sessionStorage.setItem('selectedProductId', selectedProductId);
                window.location.href = data.url;
            } else {
                setError('Could not initiate checkout. Please try again.');
            }
        } catch (err) {
            const errorMessage = err.response?.data?.error || 'An error occurred. Please try again later.';
            setError(errorMessage);
            console.error('Payment error:', err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gray-100/50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto">
                <div className="text-center sm:mb-12 mb-4">
                    <div className="flex justify-center mb-1 items-center gap-2">
                        <ScribbleText
                            text="Buy Credits"
                            sizeFactor={0.7}
                            fillColor="#027a9f"
                            strokeColor="#00a4d7"
                            roughness={0.6}
                            strokeWidth={1.5}
                            animate={true}
                        />
                        <button
                            onClick={() => setIsExplanationOpen(true)}
                            className="text-gray-400 hover:text-blue-600 transition-colors p-1 rounded-full hover:bg-blue-50"
                        >
                            <CircleHelp className="w-6 h-6" />
                        </button>
                    </div>
                    <p className="text-center text-gray-600 font-children text-base">Pay once, no subscription required</p>

                </div>

                <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
                    {/* Basic Package */}
                    <motion.div 
                        className="bg-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 overflow-visible relative group flex flex-col"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.1 }}
                    >
                        <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"/>
                        <div className="px-6 py-8 relative flex-grow flex flex-col">
                            <div>
                                <h3 className="text-2xl font-bold text-gray-900 font-children sm:mb-16 :mb-8">Starter Pack</h3>
                                <p className="text-gray-600 font-children text-lg mb-6"></p>
                                <p className="mb-8 flex items-baseline">
                                    <span className="text-5xl font-extrabold text-blue-600 font-children">€5</span>
                                </p>
                                <ul className="space-y-4">
                                    <FeatureItem isCredit={true} onInfoClick={() => setIsExplanationOpen(true)}>200</FeatureItem>
                                    <FeatureItem>Access to all AI models</FeatureItem>
                                </ul>
                            </div>
                            <div className="mt-auto pt-8">
                                <button
                                    onClick={() => handlePayment(productIds[0])}
                                    disabled={loading}
                                    className="w-full border-b-4 bg-blue-500 border-blue-600 hover:bg-blue-600 text-white font-children text-lg py-3 px-4 rounded-lg transition-colors duration-300 disabled:opacity-50 transform hover:scale-[1.02] active:scale-[0.98] flex items-center justify-center gap-2"
                                >
                                    <CreditCard className="w-5 h-5" />
                                    {loading ? 'Processing...' : 'Buy Credits'}
                                </button>
                            </div>
                        </div>
                    </motion.div>

                    {/* Standard Package */}
                    <motion.div 
                        className="bg-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 overflow-visible relative group transform scale-105 border-2 border-blue-400 flex flex-col"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        <BonusSticker amount={50} />
                        <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"/>
                        <div className="px-6 py-8 relative flex-grow flex flex-col">
                            <div>
                                <h3 className="text-2xl font-bold text-gray-900 font-children sm:mb-16 mb-8">Standard Package</h3>
                                <p className="text-gray-600 font-children text-lg mb-6"></p>
                                <p className="mb-8 flex items-baseline">
                                    <span className="text-5xl font-extrabold text-blue-600 font-children">€10</span>
                                </p>
                                <ul className="space-y-4">
                                    <FeatureItem isCredit={true} bonusAmount={50} onInfoClick={() => setIsExplanationOpen(true)}>500</FeatureItem>
                                    <FeatureItem>Access to all AI models</FeatureItem>
{/*                                     <FeatureItem>Standard Support</FeatureItem>
 */}                                </ul>
                            </div>
                            <div className="mt-auto pt-8">
                                <button
                                    onClick={() => handlePayment(productIds[1])}
                                    disabled={loading}
                                    className="w-full bg-blue-500 border-b-4 border-blue-600 hover:bg-blue-600 text-white font-children text-lg py-3 px-4 rounded-lg transition-colors duration-300 disabled:opacity-50 transform hover:scale-[1.02] active:scale-[0.98] flex items-center justify-center gap-2"
                                >
                                    <CreditCard className="w-5 h-5" />
                                    {loading ? 'Processing...' : 'Buy Credits'}
                                </button>
                            </div>
                        </div>
                    </motion.div>

                    {/* Pro Package */}
                    <motion.div 
                        className="bg-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 overflow-visible relative group flex flex-col"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                    >
                        <BonusSticker amount={150} />
                        <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"/>
                        <div className="px-6 py-8 relative flex-grow flex flex-col">
                            <div>
                                <h3 className="text-2xl font-bold text-gray-900 font-children sm:mb-16 :mb-8">Advanced Pack</h3>
                                <p className="text-gray-600 font-children text-lg mb-6"></p>
                                <p className="mb-8 flex items-baseline">
                                    <span className="text-5xl font-extrabold text-blue-600 font-children">€25</span>
                                </p>
                                <ul className="space-y-4">
                                    <FeatureItem isCredit={true} bonusAmount={150} onInfoClick={() => setIsExplanationOpen(true)}>1500</FeatureItem>
                                    <FeatureItem>Access to all AI models</FeatureItem>{/* 
                                    <FeatureItem>Priority Support</FeatureItem>
                                    <FeatureItem>Get your best drawings on the homepage!</FeatureItem> */}
                                </ul>
                            </div>
                            <div className="mt-auto pt-8">
                                <button
                                    onClick={() => handlePayment(productIds[2])}
                                    disabled={loading}
                                    className="w-full bg-blue-500 border-b-4 border-blue-600 hover:bg-blue-600 text-white font-children text-lg py-3 px-4 rounded-lg transition-colors duration-300 disabled:opacity-50 transform hover:scale-[1.02] active:scale-[0.98] flex items-center justify-center gap-2"
                                >
                                    <CreditCard className="w-5 h-5" />
                                    {loading ? 'Processing...' : 'Buy Credits'}
                                </button>
                            </div>
                        </div>
                    </motion.div>
                </div>

                {error && (
                    <motion.div 
                        className="mt-8 text-center text-red-600 font-children text-lg"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                    >
                        {error}
                    </motion.div>
                )}

                <div className="mt-2 text-center">
                    <div className="inline-flex items-center justify-center w-full">
                        <hr className="w-64 h-px my-8 bg-gray-300 border-0" />
                        <span className="absolute px-3 font-children text-gray-500 -translate-x-1/2 bg-gray-100/50 left-1/2 text-lg">OR</span>
                    </div>
                    <button 
                        onClick={() => setIsCouponModalOpen(true)}
                        className="text-white font-children text-lg hover:text-purple-600 transition-colors flex items-center gap-2 bg-purple-600/70 px-6 py-3 rounded-lg shadow-sm hover:shadow-md mx-auto border-b-4 border-purple-600/80"
                    >
                        <Gift className="w-5 h-5" />
                        Redeem a coupon
                    </button>
                </div>
            </div>

            <AnimatePresence>
                {isCouponModalOpen && (
                    <CouponModal 
                        isOpen={isCouponModalOpen} 
                        onClose={() => setIsCouponModalOpen(false)} 
                    />
                )}
                {isExplanationOpen && (
                    <CreditsExplanation
                        isOpen={isExplanationOpen}
                        onClose={() => setIsExplanationOpen(false)}
                    />
                )}
                {isDonationOpen && (
                    <Donation
                        isOpen={isDonationOpen}
                        onClose={() => setIsDonationOpen(false)}
                        onProceed={handleDonationProceed}
                        selectedPackage={selectedProductId}
                    />
                )}
            </AnimatePresence>
        </div>
    );
};

// Wrap the entire component with Stripe Elements
const Payment = () => {
    return (
        <Elements stripe={stripePromise}>
            <PaymentContent />
        </Elements>
    );
};

export default Payment;
