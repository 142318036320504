import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import ScribbleText from '../UI/ui_scribble_text.component';
import { useSelector } from 'react-redux';
import { Heart } from 'lucide-react';
import api from '../../Hooks/ApiHandler';
import { useSearchParams, Link } from 'react-router-dom';

const PaymentSuccess = () => {
    const [status, setStatus] = useState('pending');
    const [creditsAdded, setCreditsAdded] = useState(0);
    const [showDonationThanks, setShowDonationThanks] = useState(false);
    const [donationAmount, setDonationAmount] = useState(null);
    const credits = useSelector(state => state.website.credits);
    const [searchParams] = useSearchParams();
    const TEST_MODE = false;

    useEffect(() => {
        if (TEST_MODE) {
            setStatus('success');
            setCreditsAdded(100);
            setDonationAmount(5);
            return;
        }

        const sessionId = searchParams.get('session_id');
        if (!sessionId) {
            setStatus('error');
            return;
        }

        const verifyPayment = async () => {
            try {
                const { data } = await api.get(`/user/payment/verify-session/${sessionId}`);
                
                if (data.sessionStatus === 'complete') {
                    setStatus('success');
                    setCreditsAdded(parseInt(data.creditsAdded));
                    if (data.donation) {
                        setDonationAmount(data.donation.amount);
                    }
                } else {
                    setStatus('error');
                }
            } catch (error) {
                console.error('Error verifying payment:', error);
                setStatus('error');
            }
        };

        verifyPayment();
    }, [searchParams]);

    const handleContinue = () => {
        if (donationAmount) {
            setShowDonationThanks(true);
        } else {
            window.location.href = '/create';
        }
    };

    if (showDonationThanks) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-pink-50 to-white">
                <motion.div 
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ 
                        type: "spring",
                        stiffness: 200,
                        damping: 25,
                        duration: 0.8 
                    }}
                    className="max-w-lg w-full p-10 bg-white rounded-3xl shadow-xl border border-pink-100"
                >
                    <div className="text-center space-y-8">
                        <motion.div
                            initial={{ scale: 0, rotate: -180 }}
                            animate={{ scale: 1, rotate: 0 }}
                            transition={{ 
                                type: "spring",
                                stiffness: 260,
                                damping: 20,
                                duration: 1 
                            }}
                            className="flex justify-center"
                        >
                            <div className="bg-gradient-to-br from-pink-100 to-pink-200 p-5 rounded-full mb-4 shadow-inner">
                                <motion.div
                                    animate={{ 
                                        scale: [1, 1.2, 1],
                                    }}
                                    transition={{
                                        duration: 1.5,
                                        repeat: Infinity,
                                        repeatType: "reverse",
                                        ease: "easeInOut"
                                    }}
                                >
                                    <Heart className="w-16 h-16 text-pink-500" />
                                </motion.div>
                            </div>
                        </motion.div>

                        <motion.div
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.3, duration: 0.6 }}
                            className="space-y-4"
                        >
                            <h2 className="text-4xl font-bold text-gray-900 font-children mb-2">
                                Thank You!
                            </h2>
                            <motion.p 
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.5, duration: 0.6 }}
                                className="text-gray-600 font-children text-xl leading-relaxed"
                            >
                                Your generous donation of{' '}
                                <span className="font-bold text-pink-500 text-2xl">{donationAmount} CHF</span>{' '}
                                will help bring smiles to children in hospitals.
                            </motion.p>
                            <motion.p
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.7, duration: 0.6 }}
                                className="text-gray-500 font-children text-lg italic hover:text-pink-500 transition-colors"
                            >
                                <Link to="/about?section=donations" className="border-b text-pink-500 border-pink-500">
                                    How is my donation helping?
                                </Link>
                            </motion.p>
                        </motion.div>

                        <motion.div
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 1.2, duration: 0.6 }}
                            className="pt-4"
                        >
                            <button
                                onClick={() => window.location.href = '/create'}
                                className="bg-gradient-to-r from-pink-500 to-pink-600 text-white px-8 py-2 rounded-2xl 
                                    font-children text-xl tracking-wide
                                    hover:from-pink-600 hover:to-pink-700 transition-all duration-300 
                                    transform hover:scale-105 hover:-translate-y-1
                                    shadow-lg hover:shadow-xl active:scale-95"
                            >
                                OK
                            </button>
                        </motion.div>
                    </div>
                </motion.div>
            </div>
        );
    }

    if (status === 'success') {
        return (
            <div className="min-h-screen flex items-center justify-center bg-gray-50">
                <motion.div 
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ 
                        type: "spring",
                        stiffness: 260,
                        damping: 20,
                        duration: 0.6 
                    }}
                    className="max-w-md w-full p-8 bg-white rounded-2xl shadow-lg"
                >
                    <div className="text-center space-y-6">
                        <motion.div
                            initial={{ y: -20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.3, duration: 0.5 }}
                            className="flex justify-center"
                        >
                            <ScribbleText
                                text={`+${creditsAdded} Credits`}
                                sizeFactor={1.8}
                                fillColor="#10B981"
                                strokeColor="#059669"
                                roughness={0.6}
                                strokeWidth={2}
                                animate={true}
                            />
                        </motion.div>

                        <motion.p 
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.5, duration: 0.5 }}
                            className="text-gray-600 font-children text-xl"
                        >
                            New balance: <span className="font-bold text-blue-500">{credits}</span> <span className="text-blue-500">credits</span>
                        </motion.p>

                        <motion.div
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.7, duration: 0.5 }}
                        >
                            <button
                                onClick={handleContinue}
                                className="bg-blue-500 text-white px-8 py-2 rounded-xl font-children text-lg
                                    hover:bg-blue-600 transition-all duration-200 transform hover:scale-105 mt-8
                                    shadow-md hover:shadow-lg"
                            >
                                OK
                            </button>
                        </motion.div>
                    </div>
                </motion.div>
            </div>
        );
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50">
            <div className="max-w-md w-full space-y-8 p-8">
                <div className="text-center">
                    <div className="flex justify-center mb-4">
                        <ScribbleText
                            text={status === 'pending' ? 'Processing Payment' : 'Payment Error'}
                            sizeFactor={0.7}
                            fillColor="#027a9f"
                            strokeColor="#00a4d7"
                            roughness={0.6}
                            strokeWidth={1.5}
                            animate={true}
                        />
                    </div>
                    
                    {status === 'pending' ? (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            className="space-y-4"
                        >
                            <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin mx-auto"></div>
                            <p className="text-gray-600 font-children text-xl">
                                Please wait while we process your payment...
                            </p>
                            <p className="text-gray-500 font-children text-sm">
                                This may take a few moments
                            </p>
                        </motion.div>
                    ) : (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            className="space-y-4"
                        >
                            <p className="text-red-600 font-children text-xl">
                                Payment Error
                            </p>
                            <button
                                onClick={() => window.location.href = '/payment'}
                                className="mt-4 bg-blue-500 text-white px-6 py-2 rounded-lg font-children hover:bg-blue-600 transition-colors"
                            >
                                Return to Payment Page
                            </button>
                        </motion.div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PaymentSuccess; 