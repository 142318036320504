import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { Gift, X } from 'lucide-react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch } from 'react-redux';
import { updateCredits } from '../../redux/websiteSlice';
import PropTypes from 'prop-types';
import api from '../../Hooks/ApiHandler';

const RECAPTCHA_SITE_KEY = process.env?.REACT_APP_RECAPTCHA_SITE_KEY;

const CouponModal = ({ isOpen, onClose }) => {
    const dispatch = useDispatch();
    const [couponCode, setCouponCode] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const recaptchaRef = useRef(null);
    const [captchaValue, setCaptchaValue] = useState(null);

    const handleBackdropClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const handleCouponChange = (e) => {
        const value = e.target.value.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
        let formattedValue = value;
        if (value.length > 4) {
            formattedValue = value.slice(0, 4) + '-' + value.slice(4);
        }
        if (value.length > 8) {
            formattedValue = formattedValue.slice(0, 9) + '-' + formattedValue.slice(9);
        }
        setCouponCode(formattedValue.slice(0, 14));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (!captchaValue) {
            setError('Please verify that you are not a robot');
            return;
        }

        setIsSubmitting(true);
        
        try {
            const response = await api.post('/user/coupons/redeem', {                
                couponCode: couponCode.replace(/-/g, ''),
                recaptchaToken: captchaValue
            });

            const { creditsAdded, newTotal } = response.data;
            dispatch(updateCredits(newTotal));
            
            setSuccess(`Successfully redeemed coupon for ${creditsAdded} credits!`);
            setCouponCode('');
            setCaptchaValue(null);
            recaptchaRef.current?.reset();

            // Close modal after a short delay
            setTimeout(() => {
                onClose();
            }, 2000);
        } catch (err) {
            setError(err.response?.data?.message || 'Error redeeming coupon');
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!isOpen) return null;

    return (
        <div 
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
            onClick={handleBackdropClick}
        >
            <motion.div 
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white rounded-xl shadow-xl max-w-md w-full p-6 relative"
            >
                <button 
                    onClick={onClose}
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 transition-colors"
                >
                    <X className="w-6 h-6" />
                </button>

                <div className="text-center mb-6">
                    <div className="h-12 w-12 rounded-full bg-blue-100 mx-auto flex items-center justify-center mb-4">
                        <Gift className="h-6 w-6 text-blue-600" />
                    </div>
                    <h3 className="text-xl font-semibold text-gray-900">Redeem Coupon</h3>
                    <p className="mt-2 text-sm text-gray-500">Enter your 12-character coupon code to add credits to your account</p>
                </div>

                <form onSubmit={handleSubmit} className="space-y-4">
                    <input
                        type="text"
                        value={couponCode}
                        onChange={handleCouponChange}
                        placeholder="XXXX-XXXX-XXXX"
                        className="w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm 
                            focus:ring-2 focus:ring-blue-500 focus:border-blue-500 
                            text-center uppercase tracking-wider font-mono text-lg"
                        maxLength="14"
                    />

                    <div className="flex justify-center">
                        {RECAPTCHA_SITE_KEY ? (
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={RECAPTCHA_SITE_KEY}
                                onChange={(value) => setCaptchaValue(value)}
                                size="normal"
                            />
                        ) : (
                            <div className="text-red-500 text-xs">
                                ReCAPTCHA configuration error. Please try again later.
                            </div>
                        )}
                    </div>

                    {error && (
                        <div className="text-red-500 text-sm text-center animate-fade-in">
                            {error}
                        </div>
                    )}

                    {success && (
                        <div className="text-green-500 text-sm text-center animate-fade-in">
                            {success}
                        </div>
                    )}

                    <button
                        type="submit"
                        disabled={couponCode.replace(/-/g, '').length !== 12 || !captchaValue || isSubmitting}
                        className={`w-full flex justify-center py-3 px-4 border border-transparent rounded-md 
                            shadow-sm text-sm font-medium text-white transition-colors
                            ${isSubmitting || couponCode.replace(/-/g, '').length !== 12 || !captchaValue
                            ? 'bg-blue-300 cursor-not-allowed'
                            : 'bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                        }`}
                    >
                        {isSubmitting ? (
                            <div className="flex items-center">
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Processing...
                            </div>
                        ) : 'Redeem Coupon'}
                    </button>
                </form>
            </motion.div>
        </div>
    );
};

CouponModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default CouponModal;
