import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Heart, X, Gift, Ban } from 'lucide-react';
import PropTypes from 'prop-types';

const DonationOption = ({ amount, onClick, isSelected }) => (
    <button
        onClick={() => onClick(amount)}
        className={`${
            amount === 'none'
                ? isSelected 
                    ? 'bg-red-200 border-red-500 text-red-700'
                    : 'bg-white hover:bg-red-50 border-red-300 text-red-700'
                : isSelected 
                    ? 'bg-pink-200 border-pink-500 text-pink-700' 
                    : 'bg-white hover:bg-pink-50 border-gray-200 text-gray-700'
        } border-2 rounded-lg px-6 py-3 font-children text-lg transition-all duration-300 transform hover:scale-105 flex items-center justify-center gap-2`}
    >
        {amount === 'none' ? (
            <>
                <Ban className="w-4 h-4" />
                No donation
            </>
        ) : amount === 'custom' ? (
            'Custom Amount'
        ) : (
            `€${amount}`
        )}
    </button>
);

DonationOption.propTypes = {
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    onClick: PropTypes.func.isRequired,
    isSelected: PropTypes.bool.isRequired
};

const Donation = ({ isOpen, onClose, onProceed }) => {
    const minMaxDonation = [1, 50]
    const [selectedAmount, setSelectedAmount] = useState(2);
    const [customAmount, setCustomAmount] = useState('5');
    const [customError, setCustomError] = useState('');
    const donationOptions = ['none', 1, 2, 'custom'];

    const handleBackdropClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const validateCustomAmount = (value) => {
        const numValue = parseFloat(value);
        if (isNaN(numValue) || numValue <= minMaxDonation[0]) {
            return `Enter a valid amount over €${minMaxDonation[0]}`;
        }
        if (!/^\d+(\.\d{0,2})?$/.test(value)) {
            return 'Please enter a valid donation amount';
        }
        if (numValue > minMaxDonation[1]) {
            return `Maximum donation amount is €${minMaxDonation[1]}`;
        }
        return '';
    };

    const handleCustomAmountChange = (e) => {
        const value = e.target.value;
        setCustomAmount(value);
        setCustomError(validateCustomAmount(value));
    };

    const handleProceed = () => {
        if (selectedAmount === 'custom') {
            const error = validateCustomAmount(customAmount);
            if (error) {
                setCustomError(error);
                return;
            }
        }
        const finalDonationAmount = selectedAmount === 'custom' ? Number(customAmount) : selectedAmount;
        onProceed(finalDonationAmount || 0);
    };

    if (!isOpen) return null;

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-start justify-center p-4"
            onClick={handleBackdropClick}
        >
            <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white rounded-xl p-6 max-w-lg w-full relative shadow-xl"
                onClick={(e) => e.stopPropagation()}
            >
                <button
                    onClick={onClose}
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 transition-colors"
                >
                    <X className="w-6 h-6" />
                </button>

                <div className="text-center mb-6">
                    <div className="flex justify-center mb-4">
                        <div className="bg-pink-100 p-3 rounded-full">
                            <Heart className="w-8 h-8 text-pink-500" />
                        </div>
                    </div>
                    <h2 className="text-2xl font-bold text-gray-900 font-children mb-2">Help us bring Joy to <u className="text-pink-500 ">Sick Children</u></h2>
                    <p className="text-gray-600 font-children text-lg">
                        We&apos;re working to bring crayons.me to children&apos;s hospitals, helping sick children create their own colouring pages for free.
                        Would you like to add a small donation to your purchase?
                    </p>
                </div>

                <div className="grid grid-cols-2 gap-4 mb-6">
                    {donationOptions.map((amount) => (
                        <DonationOption
                            key={amount}
                            amount={amount}
                            onClick={setSelectedAmount}
                            isSelected={selectedAmount === amount}
                        />
                    ))}
                </div>

                {selectedAmount === 'custom' && (
                    <div className="mb-6">
                        <div className="relative">
                            <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">€</span>
                            <input
                                type="number"
                                min="0"
                                step="0.5"
                                value={customAmount}
                                onChange={handleCustomAmountChange}
                                className={`w-full pl-8 pr-4 py-2 border-2 rounded-lg font-children focus:ring focus:ring-opacity-50 ${
                                    customError 
                                        ? 'border-red-300 focus:border-red-500 focus:ring-red-200' 
                                        : 'border-gray-200 focus:border-pink-500 focus:ring-pink-200'
                                }`}
                                placeholder="Enter amount (multiple of €0.50)"
                            />
                        </div>
                        {customError && (
                            <p className="mt-2 text-red-500 text-sm font-children">
                                {customError}
                            </p>
                        )}
                    </div>
                )}

                <div className="flex flex-col gap-3">
                    <button
                        onClick={handleProceed}
                        disabled={selectedAmount === 'custom' && (customError || !customAmount)}
                        className={`w-full border-b-4 text-white font-children text-lg py-3 px-4 rounded-lg transition-colors duration-300 flex items-center justify-center gap-2 ${
                            selectedAmount === 'custom' && (customError || !customAmount)
                                ? 'bg-pink-300 border-pink-400 cursor-not-allowed'
                                : selectedAmount === 'none'
                                    ? 'bg-blue-500 border-blue-600 hover:bg-blue-600'
                                    : 'bg-pink-400 border-pink-600 hover:bg-pink-600'
                        }`}
                    >
                        {selectedAmount === 'none' ? (
                            <>
                                Proceed with no donation
                            </>
                        ) : (
                            <>
                                <Gift className="w-5 h-5" />
                                {`Proceed with €${selectedAmount === 'custom' ? customAmount : selectedAmount} donation`}
                            </>
                        )}
                    </button>
                </div>
            </motion.div>
        </motion.div>
    );
};

Donation.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onProceed: PropTypes.func.isRequired
};

export default Donation;
