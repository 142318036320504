import React from 'react';
import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import PropTypes from 'prop-types';

const CreditsExplanation = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    const handleBackdropClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div 
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
            onClick={handleBackdropClick}
        >
            <motion.div 
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white rounded-xl shadow-xl max-w-lg w-full p-6 relative"
            >
                <button 
                    onClick={onClose}
                    className="absolute right-4 top-4 text-gray-400 hover:text-gray-600 transition-colors"
                >
                    <X className="w-6 h-6" />
                </button>

                <div className="mt-4">
                    <h3 className="text-2xl font-bold text-gray-900 mb-4 text-center font-children">Use Credits to generate colouring pages</h3>
                    <div className="grid grid-cols-3 gap-4 text-center font-children">
                        <div className="bg-blue-200/80 backdrop-blur-sm rounded-lg p-4 shadow-lg flex flex-col">
                            <div className="text-gray-600 mb-2">Basic images</div>
                            <div className="text-2xl font-bold text-blue-600 mt-auto">1 credit</div>
                        </div>
                        <div className="bg-blue-200/80 backdrop-blur-sm rounded-lg p-4 shadow-lg flex flex-col">
                            <div className="text-gray-600 mb-2">Advanced images</div>
                            <div className="text-2xl font-bold text-blue-600 mt-auto">3 credits</div>
                        </div>
                        <div className="bg-blue-200/80 backdrop-blur-sm rounded-lg p-4 shadow-lg flex flex-col">
                            <div className="text-gray-600 mb-2">Excellent images</div>
                            <div className="text-2xl font-bold text-blue-600 mt-auto">5 credits</div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

CreditsExplanation.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default CreditsExplanation; 